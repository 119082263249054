import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { TicketRouteEnum, TicketTypeEnum } from "../constants/enum";
import { TicketHelpMessage, TicketResponseMessage } from "../interfaces/ticket.interface";
import {ContactUs, TicketMessage} from '@mediassistrepo/maven-old';
import { createTicket } from "../utils/ticket";
import HeaderWeb from "./shared/Header";

declare global {
    namespace JSX {
        interface IntrinisicElements {
            'policy-linking-wc': any
        }
    }
}

export interface Base64Decryption {
    claimID?: number;
    intimationID?: number;
}

function LinkPolicy(props:any) {
    const search = window.location.search;
    const location = useLocation();
    const query = new URLSearchParams(search);
    const isEcardRequest = query.get('isEcardRequest')
    const isIntimationRequest = query.get('isIntimationRequest')
    const isClaimRequest = query.get('isClaimRequest') 
	const [showTicket, setShowTicket] = React.useState<boolean>(false);
    const [userName,setUserName] = React.useState<string>(null)
    const [loginMethod,setLoginMethod] = React.useState<string>(null)
    const [error,setError]=React.useState<string>(null)
    const [lsKey,setlsKey]=React.useState<string>(null)
    const [types,setTypes]=React.useState<TicketTypeEnum>(null)
	const [ticketResponse, setTicketResponse] = React.useState<string>('');
    const [ticketRoute,setTicketRoute]=React.useState<any>(null)


    // searchParams.get("__firebase_request_key")
    useEffect(() => {
        const t:any = document.getElementById('policyLink');
        let source = {
            "id": 6,
            "header": "Validate yourself",
            "textFind": "Find my policy",
            "textDescription": "",
            "textView": "View your policy",
            "textFullAccess": "Get full access",
        }
        t.addEventListener('error', (e:any) => {
            if(e && e.detail ){
            setShowTicket(true);
            setError(e.detail.error);
            setlsKey(e.detail.lsKey);
            setTypes(TicketTypeEnum[e.detail.type as keyof typeof TicketTypeEnum]);
            setTicketRoute(TicketRouteEnum[e.detail.route as keyof typeof TicketRouteEnum]);
        }
        })
        if(location?.state?.data){
            t.source = location?.state?.data;
        }
        else{
            t.source = source;
        }
        t.source.type = "linkPolicy";
        t.source.isClaimRequest = (isClaimRequest && isClaimRequest === "true")?true:false;
        t.source.isEcardRequest = (isEcardRequest && isEcardRequest === "true")?true:false;
        t.source.isIntimationRequest = (isIntimationRequest && isIntimationRequest === "true")?true:false;
        t.source.sourceUrl = location?.state?.pageName ?? '/';
        t.source.fullAccessEnabled = true;
    }, []);
    const  hideTicket=()=>{
        const event= new CustomEvent('hideTicket')
        document.dispatchEvent(event)
    }
    useEffect(()=>{
       setUserName(location.state?.data?.username) 
       setLoginMethod(location.state?.data?.loginMethod)
    },[])
  
    return (
        <React.Fragment>
            <HeaderWeb linkPolicyHeader={true} />
                <div>
                    <policy-linking-wc id="policyLink"></policy-linking-wc>
                    {  showTicket && <ContactUs type={types} failureRoute={ticketRoute} helpMessage={TicketHelpMessage.ValidationFailure} createTicket={(type:TicketTypeEnum, failureRoute: TicketRouteEnum, email: string,lsKey: string ) => createTicket(type, failureRoute, email, userName, error, loginMethod,lsKey).then(res => {
					if (res?.isSuccess) {
                        hideTicket();
                        setShowTicket(false)
						setTicketResponse(TicketResponseMessage.SuccessMessage);
					} else {
						setTicketResponse(TicketResponseMessage.FailureMessage);
					}
					return res;
				})} lsKey={lsKey} handleClose={() => setShowTicket(false)} />}
				{ticketResponse !== '' && <TicketMessage ticketMessage={ticketResponse} handleClose={() => { setTicketResponse(''); setShowTicket(false); }} />}
                </div>
        </React.Fragment>
    );
}

export default LinkPolicy;
