import React, { useEffect, useState } from 'react';
import SurveyComponent from './Survey';
import defaultSurveyConfig from './config/suvey';
import "./customSurveyCSS.css"
import "./index.css"
import { getCookieValue, postToNativeApp } from '../../common/helpers';
import { EntityValue, SurveyJSRequestModel } from '../../interfaces/cashless.interface';
import { SetFeedBack } from '../../api/homeAPI';
import Constants from '../../constants/Constants';
export interface IApplicationProps {
    dateOfBirth?: string,
    emailId?:string,
    userName?:string,
    pageTitle: string,
    Id:string,
    source :string 
    entity_Type : string
    closeFeedbackModal: () => void
}
const SurveyMain: React.FunctionComponent<IApplicationProps> = ({dateOfBirth,emailId,userName,pageTitle,Id,source,entity_Type,closeFeedbackModal}) => {
    const [surveyTitle, setSurveyTitle] = useState<boolean>(false)
    useEffect(() => {        
            const actionBtn = document.querySelector('.sv-action__content input')
            actionBtn?.setAttribute('value', 'Submit')
            const btnDiv = document.querySelector('#sv-nav-complete')
            const surveyHTML = `<button class='cursor-pointer  remind-btn p-3 bg-none border-newGray400 text-newGray700 fw-sb text-base' style='width: 48%; border: 1px solid #B0BED1; border-radius: 4px'>Remind me later</button>`
 
            btnDiv.insertAdjacentHTML('afterbegin', surveyHTML)
            const btnRemind = document.querySelector('.remind-btn')
            btnRemind.addEventListener('click', () => {
                closeFeedbackModal()
            })    
    }, [])
    const SaveSurveyJSDATA = async (feedback_rating: number, feedback_value = 'NA') => {
        let requestObject: SurveyJSRequestModel = new SurveyJSRequestModel();
        let entityValue: EntityValue = new EntityValue();
        entityValue.feedback_value = feedback_value
        entityValue.feedback_rating = feedback_rating
        requestObject.source = source
        requestObject.entity_Type = entity_Type
        requestObject.entity_Id = Id;
        requestObject.entity_Value = entityValue
        let surveyjsresponse: any;
        try {
            surveyjsresponse = await SetFeedBack(requestObject);
            if (surveyjsresponse?.data?.isSuccess ) {
                if (feedback_rating == 4 || feedback_rating == 5) {
                    const APP_STORE_URL = Constants.Instance.APP_STORE_URL;
                    const PLAY_STORE_URL = Constants.Instance.PLAY_STORE_URL;
                    if (getCookieValue('platform') == 'ios') {
                        postToNativeApp({ networkHospitalUrl: APP_STORE_URL })
                    }
                    if (getCookieValue('platform') == 'android') {
                        postToNativeApp({ networkHospitalUrl: PLAY_STORE_URL })
                    }
                }
            }
        } catch (err) {
        }
    };
    
    return (
        <div className="relative">
            {<div className='fixed top-0 left-0 w-full h-full overflowY-auto d-flex items-center justify-center  ' style={{ backgroundColor: 'rgba(0,0,0,0.4)', zIndex: 9999}}>
                <div className='success  mx-auto mt-9   radius-mdl p-6 ' style={{width: '80%',maxWidth:"440px",border: surveyTitle ?"1px solid #00c369" :"",backgroundColor: surveyTitle?'#F0FDF4' :"#fff"}}>
                    <div>
                        <div className=' border-b border-newGray300 border-solid pb-2 mb-2 d-flex justify-between items-center gap-3'>
                            {!surveyTitle && <h2 className='text-mdl font-semibold text-newGray800' style={{marginBottom:'1rem'}}>Your input matters</h2>}
                            {surveyTitle &&<div  className="flex gap-2"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                     <rect width="20" height="20" rx="10" fill="#22C55E"/>
                                 <path d="M14.375 7.5L8.35938 13.75L5.625 10.9091" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                           <h2>Feedback received</h2></div>}
                            <button className="p-0 border-0 bg-none outline-none h-auto leading-none" onClick={closeFeedbackModal}>
                                <svg width="22.909" height="22.909" viewBox="0 0 22.909 22.909"><g id="Group_12174" data-name="Group 12174"><rect width="22.909" height="22.909" rx="11.455" fill="#e4e4e4" className="btn-close-fill"></rect><g id="close" transform="translate(11.454 6.242) rotate(45)"><line y2="7.372" transform="translate(3.686)" fill="none" stroke="#040332" stroke-linecap="round" stroke-width="1"></line><line x1="7.372" transform="translate(0 3.686)" fill="none" stroke="#040332" stroke-linecap="round" stroke-width="1"></line></g></g></svg>
                            </button>
                        </div>
                        <div style={{backgroundColor:surveyTitle? '#F0FDF4':"#fff"}}>
                            {!surveyTitle && <div>
                              <h4 className='text-base fw-r text-newGray800 mb-3'>{pageTitle}</h4>
                            </div>}
                            <SurveyComponent 
                                css={defaultSurveyConfig.defaultSurveyCSS}
                                data={{dateOfBirth,emailId,userName}}
                                json={defaultSurveyConfig.defaultSurveyJSON}
                                onComplete={(survey: any) => {
                                    SaveSurveyJSDATA(survey.data.ratings,survey.data.comments || "NA");
                                    setSurveyTitle(!surveyTitle) 
                                    const heading = document.querySelector('.sd-container-modern') as HTMLElement;
                                    if (heading) {
                                        heading.style.backgroundColor = '#F0FDF4';
                                        heading.insertAdjacentHTML('afterend', `<h3 style="background:#F0FDF4; color: #000000">Thank you for submitting your invaluable feedback.</h3>`);
                                    }
                                }
                            }
                            />
                            {/* <Button btnTheme="outline" className="w-full bg-none border-newGray400 text-newGray700 fw-sb text-base" onClick={closeFeedbackModal}>
                                Remind me later
                            </Button> */}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}
export default SurveyMain;