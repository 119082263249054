import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAccessToken, getDbType, getMaid, getXrefToken } from "../utils";
import Loader from "./shared/Loader";
import { useAppSelector } from "../app/hooks";
import { getIntimationRequest } from "../reducers/intimationRequest.slice";
import { getPlatform, getPlatformFromCookie } from "../common/helpers";

export interface Base64Decryption {
    claimID?: number;
    intimationID?: number;
}

function IntimationComponent() {
    const navigate = useNavigate();
    const intimationRequest = useAppSelector(getIntimationRequest);
    const [buttonClick, setButtonClick] = useState(null);
    const [loading, setLoading] = useState(true)
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('accessToken') || getAccessToken();
    const source = searchParams.get('source') || "Maven";
    const dbType =  searchParams.get('dbType') || getDbType();
    const maid = searchParams.get('maid') || parseInt(getMaid());
    const oop = searchParams.get("oop");
    const initializeIntimation = () =>{
        const eventQuerry = document.querySelector("intimation-web-component")
        eventQuerry?.addEventListener('GO_BACK', (e: any) => {
            navigate(-1);
        })
        eventQuerry?.addEventListener('GO_HOME', (e: any) => {
            navigate("/");
        });
    };

    useEffect(() => {
        const component: any = document.querySelector("intimation-web-component");
        if (component) {
            component.event = buttonClick;
            component.addEventListener('back', (e: any) => {
                if (e && e.detail && e.detail.intimationId) {
                    navigate('/',{state: {intimations: true}})
                }
            });
        }
    }, [buttonClick]);

    useEffect(() => {
            const component: any = document.querySelector("intimation-web-component");
        if (component) {
                component.addEventListener('intimation-success', (e: any) => {
                if (e && e.detail && e.detail.IntimationID) {
                        navigate('/oop?intimationID=' + e.detail.IntimationID)
                    }else if(e && e.detail && e.detail.data && e.detail.data.intimationId){
                        navigate('/oop?intimationID=' + e.detail.data.intimationId)
                    }
                });
            }
        setLoading(false)
        initializeIntimation();
    }, [accessToken && dbType && maid]);

    return (
        <React.Fragment>
            {loading && <Loader/>}
            <div style={{ padding: "20px", paddingBottom: "0px"}}>
                <intimation-web-component
                 maid={maid}
                 dbType={dbType}
                 isOOP={oop}
                 token={accessToken}
                 request={JSON.stringify(intimationRequest)}
                 source={getPlatform()}
                 ></intimation-web-component>
            </div>
        </React.Fragment>
    );
}

export default IntimationComponent;
