export enum TicketTypeEnum {
    PolicyNumber = 'POLICY_NUMBER',
    CorporateEmployee = 'CORPORATE_EMPLOYEE',
    ClaimId = 'CLAIM_ID',
    LoginFailure = 'LOGIN_FAILURE',
    NotMyAccount = 'NOT_MY_ACCOUNT'
 }

 export enum TicketRouteEnum {
    UserVerify = 'USER_VERIFY',
    UserIdentify = 'USER_IDENTIFY',
    AuthSignin = 'AUTH_SIGNIN'
}