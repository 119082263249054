import React, { useEffect, useState, useRef } from 'react';
import * as Survey from 'survey-react';
interface IUserData {
  dateOfBirth: string;
  emailId: string;
  userName: string;
}
export interface ISurveyProps {
  css: any;
  json: any;
  data: IUserData;
  onComplete: (survey: any) => void;
}
const SurveyComponent: React.FunctionComponent<ISurveyProps> = (props) => {
  const { css, data, json, onComplete } = props;
  const textareaRef = useRef<any>(null); 
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus(); 
    }
  }, []);
  const handleSurveyComplete = (survey: any) => {
    onComplete(survey);
  };
  const onAfterRenderQuestion = (survey: any, options: any) => {
    if (options.question.getType() === 'comment') {
        const textarea = options.htmlElement.querySelector('textarea.sd-input');
      if (textarea) {
        textareaRef.current = textarea;
        textarea.focus();
      }}
  };
  const handleValueChanged = (sender: any, options: any) => {    
    if ( textareaRef.current) { 
      textareaRef.current.focus();
    }
  };
  return (
    <Survey.Survey
      css={css}
      data={data}
      json={json}
      onComplete={handleSurveyComplete}
      onAfterRenderQuestion={onAfterRenderQuestion}
      onValueChanged={handleValueChanged}
    />
  );
};
export default SurveyComponent;