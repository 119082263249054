import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Loader from "./shared/Loader";
import { setIntimationRequest } from '../reducers/intimationRequest.slice';
import { useAppDispatch } from "../app/hooks";
function OOP() {
  const dispatch = useAppDispatch();

    const search = window.location.search;
    const query = new URLSearchParams(search);
    const navigate = useNavigate();
    const intimationID = query.get('intimationID');

    useEffect(() => {
    initializeOop();
    }, [intimationID]);

    const initializeOop = () =>{
        const eventQuerry = document.querySelector("oop-web-component")
        eventQuerry?.addEventListener('GO_BACK', (e: any) => {
            navigate(-1);
        })
        eventQuerry?.addEventListener('GO_HOME', (e: any) => {
            navigate("/");
        })
        eventQuerry?.addEventListener("intimation-success", (e: any) => {
            if (e?.detail) {
                dispatch(setIntimationRequest(e.detail))
                navigate("/intimation")
            }
          });
    }

    return (
        <React.Fragment>
            <div style={{padding:'20px'}}>
                {!intimationID ? 
                <Loader/>
                :
                <oop-web-component intimationID={intimationID}></oop-web-component>
            }
            </div>
        </React.Fragment>
    );
}

export default OOP;
