import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
interface OtherType1 {
    Type: string[];
  }
  const intialState: OtherType1 = {
    Type: [],
  };
export interface PolicyState {
  value: any;
}
const initialState: PolicyState = {
  value: intialState,
};
export const policyListSlice = createSlice({
  name: 'policyList',
  initialState,
  
  reducers: {
    setappliedFilters : (state, action: PayloadAction<any>) => {
        state.value = action.payload;
    }
  },
});
export const { setappliedFilters } = policyListSlice.actions;
export const getappliedFilters = (state: RootState) => state.policyList.value;
export default policyListSlice.reducer;