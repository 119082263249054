import React, { useEffect, useState } from "react";
import { Header } from "@mediassistrepo/maven-old";
import { useAppSelector } from "../../app/hooks";
import { getBaseProfile } from "../../reducers/baseProfile.slice";
import { useLocation, useNavigate } from "react-router";
import { getCookieValue, postToNativeApp } from "../../common/helpers";
import { getClaimList } from "../../reducers/claimList.slice";
import { getConfig } from "../../reducers/config.slice";
import useWindowWidth from "../../hooks/getWindowWidth";
import { getActiveClaims } from "../../reducers/activeClaims.slice";
import { getActiveBeneficiaries } from "../../reducers/beneficiaries.slice";
import { getflexDetails } from "../../reducers/flexDetails.slice";
import { dataLayerPush } from "../../utils/gtm";
import Constants from "../../constants/Constants";

const HeaderWeb = (props: any) => {
  const location = useLocation();
  const {
    show = true,
    isShowBackButton = false,
    oldHeader = false,
    name = "",
    onBackBtnClick = null,
    handleEcard,
    policyDuration,
    policyNumber,
    linkPolicyHeader = false,
  } = props;

  const navigate = useNavigate();
  const baseProfile = useAppSelector(getBaseProfile);
  const activeBenefs = useAppSelector(getActiveBeneficiaries);
  const [options, setOptions] = useState([
    {
      id: 0,
      name: "",
      status: "",
    },
  ]);

  const corporateConfig = useAppSelector(getConfig);
  const activeClaimsResponse = useAppSelector(getActiveClaims);
  const flexDetails = useAppSelector(getflexDetails);

  useEffect(() => {
    if (baseProfile?.data) {
      setOptions([
        {
          id: 0,
          name: baseProfile.data.firstName,
          status: "",
        },
      ]);
    }
  }, [baseProfile]);

  return (
    <div className="sticky-div">
      <Header
      dataLayerPush={dataLayerPush}
        showSubmitClaim={
          useWindowWidth() > 1023 ? props?.showSubmitClaim : false
        }
        linkPolicyHeader={linkPolicyHeader}
        corporateConfig={corporateConfig}
        policyDuration={policyDuration}
        policyNumber={policyNumber}
        style={{ width: "100%", display: "flex" }}
        hiddenMb={!show}
        oldHeader={oldHeader}
        isShowBackBtn={isShowBackButton}
        name={name}
        subtitle={""}
        onBackBtnClick={() => {
          onBackBtnClick ? onBackBtnClick() : navigate(-1);
        }}
        options={options}
        onLogoutClick={() => {
          dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
            header: "Profile",
            sub_header:"Logout"
          });
          navigate("/logout");
        }}
        onLinkAccountClick={() => {
          dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
            header: "Profile",
            sub_header:"Link Account"
          });
          navigate("/link-policy")}}
        claimsCount={
          activeClaimsResponse?.claims?.length || 0
        }
        actions={{
          onSubmitClaimClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Submit Claim"
            });
            // navigate("/claimSubmission/IPD");
          },
          onTrackClaim: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Track Claim"
            });
            navigate("/claim-list");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Track Claim"
            });
          },
          onSearchNetworkHospital: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Search Network Hospital"
            });
            navigate("/hospitals");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Search Network Hospital"
            });
          },
          onDownloadECard: () => {
            props?.setSectionName?.(Constants.Instance.TOP_NAVIGATION_BAR)
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Download E-card"
            });
            handleEcard();
          },
          onRaiseQueryClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Need help?"
            });
            navigate("/help");
          },
          onAddMemberClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Add Member"
            });
            navigate("/enrollment");
          },
          onSubmitIntimation: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Submit Intimation"
            });
            navigate("/intimation");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Submit Intimation"
            });
          },
          knowYourCoverage: () => {
            navigate("/intimation?oop=true");
          },

          handleClickClinic: () => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "Clinic/OPD"
            });
            navigate("/claimSubmission/OPD");
            navigate(0);
          },
          handleClickWellness: () => {
            navigate("");
          },
        }}
        handleClickClinic = {() => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Clinic/OPD"
          });
          navigate("/claimSubmission/OPD");
          navigate(0);
        }}
        handleClickHospital={() => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Hospitalisation"
          });
          navigate("/claimSubmission/IPD");
          navigate(0);
        }}
        navigations={{
          onHomeClick: () => {
            navigate("/");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Home",
              sub_header:"NA"
            });
          },
          onClaimsClick: () => {
            navigate("/claim-list");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Claims",
              sub_header:"NA"
            });
          },
          onPolicyClick: () => {
            navigate("/policy-list");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Policy List",
              sub_header:"NA"
            });
          },
          onProfileClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Account",
              sub_header:"NA"
            });
            if (location?.pathname?.includes("/account")) {
              navigate(0);
            } else {
              navigate("/account");
            }
          },
        }}
        onClose={props.onClose}
        hideEcardDownload={!activeBenefs?.length || corporateConfig?.hideEcard}
        flexDetails={flexDetails}
      />
    </div>
  );
};

export default HeaderWeb;
