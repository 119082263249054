import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getCookieValue, postToNativeApp } from "../common/helpers";
import { logout } from "../reducers/login.slice";
import { resetToInitial } from "../reducers/claimList.slice";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear();

    dispatch(logout());
    dispatch(resetToInitial());

    if (getCookieValue("source") === "native") {
      postToNativeApp({
        logout: "logout",
      });
    }
    navigate("/")
  })
  return (<></>)
}
export default Logout;