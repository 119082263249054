import React, { useEffect, useState } from "react";
// @ts-ignore
import { ClaimList } from "@mediassistrepo/claim-list";
import "@mediassistrepo/claim-list/dist/style.css";
import HeaderWeb from "./shared/Header";
import FooterWeb from "./shared/Footer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  familyClaimsAsync,
  getActiveFamilyClaims,
} from "../reducers/familyClaims.slice";
import { useNavigate } from "react-router";

import { ClaimListRequest } from '../interfaces/claimList.interface';
import { claimListAsync, getClaimList, getClaimListLoading } from '../reducers/claimList.slice';
import { encryptClaimId } from '../api/masterApi';
import Loader from './shared/Loader';
import { beneficiariesAsync, getActiveBeneficiaries, getBeneficiaries, setActiveBeneficiaries } from '../reducers/beneficiaries.slice';
import { feedBackCommon, getCookieValue, logAPIStatus, postToNativeApp } from '../common/helpers';
import { getLogAPIStatusDTO } from '../common/loggerDtoGenerator';
import { RouteConstant  ,LoggerConstant } from "../common/common.constants";
import {DownloadECard} from '@mediassistrepo/homepage-component';
import { formatDateToObject, getAccessToken } from '../utils';
import { downloadEcardByMember, downloadEcardFile } from "../utils/downloadEcard";
import { getBaseProfile } from "../reducers/baseProfile.slice";
import { useSearchParams } from 'react-router-dom';
import SurveyMain from "../common/Survey/SurveyMain";
import Constants from "../constants/Constants";
import { dataLayerPush } from '../utils/gtm';

export default function ClaimListComponent(props: any) {
  const dispatch = useAppDispatch();
  const claimListResponse = useAppSelector(getClaimList);
  const isClaimListLoading = useAppSelector(getClaimListLoading);
  const [benefResponse, setBenefResponse] = React.useState<any>(null);
  const nativeApp = getCookieValue("source") === "native" ? true : false;
  const beneficiariesResponse = useAppSelector(getBeneficiaries);
  const cachedActiveBeneficiaries  = useAppSelector(getActiveBeneficiaries);
  const [toggleModal, setToggleModal] = React.useState<any>(false);
  const accessToken = getAccessToken();
  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const [loading, setLoading] = React.useState(false)
  const [claimLoading, setClaimLoading] = React.useState(false);
  const baseProfile = useAppSelector(getBaseProfile);
  const [feedBackModalShow,setFeedBackModalShow]=useState(false);
  const [maid,setMaid]=useState<string>('')

  const navigate = useNavigate();


  useEffect(() => {
    if (!claimListResponse) {
      getFilteredClaimList(null, true);
    }
  }, []);

  useEffect(() => {
    if (claimListResponse)
      logAPIStatus(getLogAPIStatusDTO(LoggerConstant.CLAIM_LIST_PAGE, 'claimList/family', {}, claimListResponse, LoggerConstant.CLAIM_LIST_PAGE));
  }, [claimListResponse])

  useEffect(() => {
    if(cachedActiveBeneficiaries){
       return;
    }
    const activeBeneficiaries = beneficiariesResponse?.beneficiaryDetails
      ?.filter((benefs) => formatDateToObject(benefs?.policyEndDate) >= new Date());
    postToNativeApp({"activeBenefCount":activeBeneficiaries?.length})

    dispatch(setActiveBeneficiaries(activeBeneficiaries));

  }, [beneficiariesResponse]);
  
  const getFilteredClaimList = (request: ClaimListRequest, isBaseRequest: Boolean = false, filterValue: { id: number[]; type: 'claimTypeIds' | 'maids' | 'policyIds' } | null = null) => {
    if (isBaseRequest) {
      request = {};
    } else if (filterValue?.id?.length) {
      const { id: ids, type } = filterValue;
      if (type === 'claimTypeIds' && request.claimTypeIds) {
        request.claimTypeIds = request.claimTypeIds.filter(claimTypeId => !ids?.includes(claimTypeId));
      }

      if (type === 'maids' && request.maids) {
        request.maids = request.maids.filter(maid => !ids?.includes(maid));
      }

      if (type === 'policyIds' && request.policyIds) {
        request.policyIds = request.policyIds.filter(policyId => !ids?.includes(policyId));
      }
    }

    dispatch(claimListAsync(request));
  };

  useEffect(() => {
    dispatch(beneficiariesAsync());
  }, []);
  useEffect(() => {
    if (beneficiariesResponse) {
      setBenefResponse(beneficiariesResponse);
    }
  }, [beneficiariesResponse]);

  const onClaimClick = async (item: any) => {
    let section_name="Past Claims";
    if (
      !['settled', 'rejected', 'denied', 'repudiated', 'claim settled' ].find(x => item?.status?.toLowerCase().includes(x))) 
    {
      section_name="Active Claims";
    }
    dataLayerPush(Constants.Instance.CLAIMS_POLICIES_CARD_INTERACTION, { 
      section_name,
      card_type : item.type
    });
    setClaimLoading(true);
    try {
      const claimIdResponse = await encryptClaimId(item.data.id);
      window.location.href =
        "/old1/claimDetails/" +
        encodeURIComponent(claimIdResponse.data.encrytedClaimId);
      setClaimLoading(false);
    } catch (error) {
      setClaimLoading(false);
    }
  };

  useEffect(() => {
    if (getCookieValue('platform') == 'ios') {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
    else if(getCookieValue('platform') == 'android'){
      
      document.addEventListener('message', handleMessage);
      return () => {
        document.removeEventListener('message', handleMessage);
      };
    }
  }, []);
  const handleMessage = (event: any) => {
    const message = JSON.parse(event.data);
    if (message.event === 'urlToWeb') {
      if (message.data.includes('claim-list')) {
        navigate("/claim-list")
      }
      else if (message.data.includes('policy-list')) {
        navigate("/policy-list")
      }
      else if (message.data.includes('account')) {
        navigate("/account")
      }
      else {
        navigate("/")
      }
    }
    else if (message.event === 'EcardSuccess') {
      HandlefeedBack("E_CARD");
    }
  };
  const HandlefeedBack= async (entityType :string)=>{
    const show= await feedBackCommon(entityType)
    setFeedBackModalShow(show);
    setMaid(localStorage.getItem("maid") )
  }
  return (
    <>
      {(loading) && <Loader />}
      {feedBackModalShow && <div className="relative">
      <SurveyMain pageTitle='E-card successfully downloaded.' Id={maid} source ={"ecard"} entity_Type={"E_CARD"} closeFeedbackModal={() => setFeedBackModalShow(!feedBackModalShow)}/>
      </div>}
      {isClaimListLoading && <Loader />}
      {claimLoading && <Loader />}
     {localStorage.getItem('source') !== Constants.Instance.MEDIBUDDY && <HeaderWeb show={false}    //hide the header in Medibuddy app when opened in external browser 
        handleEcard={() => props?.setIsEcardOpen(true)}
        showSubmitClaim={props?.showClaimSubmissionModal}
        handleClickHospital={() => {
        }}
        handleClickClinic={() => {navigate("/claimSubmission/OPD"); navigate(0)}}
        handleClickWellness={() => { navigate("")}}
        onClose={() => {
        props?.setShowClaimSubmissionModal(false)}} />}

      <DownloadECard
        sectionName={Constants.Instance.TOP_NAVIGATION_BAR} 
        dataLayerPush={dataLayerPush}  
        isVisible={props.isEcardOpen}
        toggleBottomModal={() => {
          props.setIsEcardOpen(false)
        }}
        membersData={cachedActiveBeneficiaries ?? []}
        handleEcardDownload={async (selectedMaids: any) => {
          await downloadEcardByMember((loading: boolean) => setLoading(loading), baseProfile,selectedMaids );
            HandlefeedBack("E_CARD");
        }}
        onCloseClick={() => {
          if (getCookieValue('source') === 'native') {
            postToNativeApp({ "hideFooter": false })
            }
          props.setIsEcardOpen(false);
        }}
      />

      <div className="px-5 mx-auto" style={{ paddingBottom: "100px" }}>
        {claimListResponse && benefResponse && (
          <ClaimList
            claims={claimListResponse?.searchResults}
            onClaimClick={onClaimClick}
            benefResponse={
              benefResponse
                ? benefResponse
                : { beneficiaryDetails: [], policyDetails: [] }
            }
            dataLayerPush = {dataLayerPush}
            onClickApply={(data: any) => {
              getFilteredClaimList(data);
            }}
            onClickClear={() => {
              getFilteredClaimList(null, true);
            }}
            onClickFilter={(filterClicked: Boolean) => {
              if(filterClicked){
                dataLayerPush(Constants.Instance.CLAIMS_POLICIES_CTA_INTERACTION, { 
                  cta_text:"Filter"
                });
              }
              postToNativeApp({ hideFooter: filterClicked });
            }}
            handleClearAll={()=>{
              dataLayerPush(Constants.Instance.CLAIMS_POLICIES_CTA_INTERACTION, { 
                cta_text:"Clear All"
              });
              getFilteredClaimList(null, true);
              dataLayerPush(Constants.Instance.CLAIMS_POLICIES_CTA_INTERACTION, { 
                cta_text:"Clear All"
              });
            }}
            individualFiltered ={(filteredValue:any,individualValue:any)=>{
               getFilteredClaimList(filteredValue, false,individualValue);
            }}
          />
        )}
        
        {(!nativeApp || props.showClaimSubmissionModal) && localStorage.getItem('source') !== Constants.Instance.MEDIBUDDY && <FooterWeb handleEcard={() => props.setIsEcardOpen(true)} showSubmitClaim={props.showClaimSubmissionModal} onClose={()=>{        //hide the footer in Medibuddy app when opened in external browser 
         postToNativeApp({ "hideFooter": false })
         props.setShowClaimSubmissionModal(false)
       }}/>}
      </div>
    </>
  );
}
