import { Help } from "@mediassistrepo/maven-old";
import { useAppSelector } from "../app/hooks";
import { getConfig, isConfigLoading } from "../reducers/config.slice";
import Loader from "./shared/Loader";
import { dataLayerPush } from '../utils/gtm';

const HelpSection = () => {
    const corporateConfig = useAppSelector(getConfig);
    const configLoading = useAppSelector(isConfigLoading);

  return (
    <>
    {configLoading && <Loader />}
    {!configLoading && <Help configData={corporateConfig} dataLayerPush={dataLayerPush}/>}
    </>

  )
}

export default HelpSection