// src/components/PrivateRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { setAccessToken } from '../utils';
import { useAppDispatch } from '../app/hooks';
import { ssoSamlLoginAsync } from '../reducers/login.slice';
import Loader from '../components/shared/Loader';


const PrivateRoute = ({consent}:{consent:boolean}) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('accessToken');
    const source = searchParams.get('source'); //I am passing this source from Medibuddy app as 'medibuddy'

    const isAuthenticated = localStorage.getItem("accessToken"); 
    const [ loading, setLoading ] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    

    useEffect(() => {
        if(source){
            localStorage.setItem('source',source) //This was added for MB to MA changes
        }
        if(accessToken){
            logIn(accessToken);
        } else {
            setLoading(false);
        }
    }, [loading]);

    const logIn = async (token: string) => {
        const res = await dispatch(ssoSamlLoginAsync({ token }));
        if(res && res.payload && res.payload.accessToken) {
            window.location.href = window.location.pathname
        }
        setLoading(false);
    }
    
    if (loading) {
        return <Loader/>;
    }
    
    if(accessToken){
        setAccessToken(accessToken);
    }

    if (!isAuthenticated) {
        // Save the intended route to localStorage
        localStorage.setItem('intendedRoute', location.pathname);
        return <Navigate to="/" />;
    }

    if (isAuthenticated && consent) {
        return <Navigate to="/terms-and-conditions" />;
    }

    return <Outlet />;
};

export default PrivateRoute;