import { BaseResponse } from "./claimList.interface";

export interface MultiUserDetailsResponse{
  isMultiuser: boolean;
}

export class IMultiUserDetails {
  username: string;
  isVerified: boolean;
}

export interface MultiUserDataResponse {
  encryptedToken: string;

  users: IMultiUserDetails[];

  moreThanFive: boolean;
}


export interface SIProtectResponseDto extends BaseResponse {
  siProtectDetails: SIProtectDetails;
}
export class UpdateBankDetailsResponse {
  isSuccess: Boolean | undefined;
  error: string | undefined;
}

export class FetchChequeLeafResponse extends BaseResponse {
  fileURL: string;
}

export class SIProtectDetailsRequest {
	forEmail: boolean = false;
	forMobile: boolean = false;
	subscribe: boolean = true;
}

export interface SIProtectDetails {
  priBenefEmail:string;
  priBenefMobileNumber:string;
  isSIProtected: boolean;
  isPaymentProtected: boolean;
  priEmpCode: string;
  dbType: string;
  policyId: string;
  maid: string;
  isSuccess: boolean;
  errorMessage:boolean;
}

export interface BeneficiaryV2Response extends BaseResponse {
  isCountAvailable: boolean
  totalRecordsCount: number;
  beneficiaryDetails: Array<BeneficiaryDetailsV2>
}

export interface BeneficiaryDetailsV2 {
  benefMemberInsurerNo: string
  benefActive: boolean;
  benefAddDate: Date;
  benefAddUser: number;
  benefAge: number;
  benefAlphaCode: string
  benefAmtAuthorised: number;
  benefAmtSpent: number;
  benefAreaCode: string
  benefBloodGroup: string
  benefBufferAmt: number;
  benefBufferAmtAuthorized: number;
  benefCardPrintCount: number;
  benefCardprintdate: Date;
  benefCardPrinted10Cards: boolean;
  benefCardPrinted18Cards: boolean;
  benefCardrePrintDate: Date;
  benefCashLessBlock: boolean;
  benefCBChanged: boolean;
  benefCBOriginalAmt: number;
  benefCholaRelCode: string
  benefConvalescenceBenefit: number;
  benefCoveredThrough: string
  benefCoveredUnderPrevPol: boolean;
  benefCumBonusAmt: number;
  benefCumBonusPer: number;
  benefDailyCashBenefit: number;
  benefDateOfPolCovered: Date;
  benefDeletionDate: Date;
  benefDeletionEndoId: number;
  benefDeletionTPAIntDate: Date;
  benefDiabetesCvrd: boolean;
  benefDOB: Date;
  benefDomiLimitOPD: number;
  benefDomiOPDSpent: number;
  benefDomiSpent: number;
  benefDomLimit: number;
  benefEmail: string
  benefEmgContactPerson: string
  benefEmgNo: string
  UnMaskedBenefEmail: string
  UnMaskedBenefEmgNo: string
  benefEndoID: number;
  benefEndorsementReceived: boolean;
  benefExclusion: string
  benefExemption: boolean;
  benefFamilyDoctor: string
  benefImageExists: boolean;
  benefImagePath: string
  benefInceptionDate: Date;
  benefIsTransfer: boolean;
  benefIsVip: boolean;
  beneflIsHypertensionCvrd: boolean;
  benefMediAssistID: number;
  benefModDate: Date;
  benefModUser: number;
  benefName: string
  benefNIAPersonID: string
  benefNominee: string
  benefOccID: number;
  OccnName: string
  benefPED_DM: string
  benefPED_HTN: string
  benefPolHldrID: number;
  benefPolicyHospBenefit: number;
  benefPolID: number;
  benefPolincdate: Date;
  benefPremium: number;
  benefPriID: number;
  benefRejoiningDate: Date;
  benefRelDuplicate: boolean;
  benefRelName: string
  benefRelSex: string
  benefRelToPriID: number;
  benefRemarks: string
  benefSex: string
  benefSlNoasperInsurer: string
  benefStatus: string
  benefSumInsured: number;
  benefThresholdAmt: number;
  benefTopUp: number;
  benefTopupEffDate: Date;
  benefTotalSumInsured: number;
  benefUserID: number;
  benefWEF: Date;
  benefWETo: Date;
  benefZone: string
  CardType: string
  CASBenefId: number;
  entityName: string
  iSUHSMAID: number;
  policyDetail: PolicyHeaderV2
  primaryBenefDetails: PribeneficiaryDetailV2
  sourceDBType: DBType;
  subEntityName: string
  endorsementDetail: EndorsementDetailV2
  benefAddUserName: string
  migBenefOCC: string
  migBenefRelToPri: string
  migBenefImagePath: string
  migBenefCardPrintDate: Date;
  migBenefCardReleaseDate: Date;
  migBenefCardReprintDate: Date;
  migBenefLstModUser: number;
  migBenefLstModDate: Date;
  jobId: string
  jobType: string
  isPaymentProtected: boolean;
  isSIProtected: boolean;
  priBenefMobileNumber: string;
  priBenefEmail: string;
  minDate: string;
  maxDate: string;
}

interface EndorsementDetailV2 {
  endoAddition: number;
  endoAddUser: string;
  endoBuffer: string;
  endoClause: string;
  endoDate: Date;
  endoDeletion: number;
  endoExcess: string;
  endoExclusion: string;
  endoID: number;
  endoMaternity: string;
  endoModdate: Date;
  endoNo: string;
  endoOldAppId: number;
  endoPolId: number;
  endoPolNum: string;
  endoPremium: number;
  endoRemarks: string;
  endoRsPolNo: string;
  endoWEF: Date;
  sourceDBType: string;
}

export enum DBType {
  NOTAVAILABLE = 0,
  MA,
  MAUHS,
  IWP,
  PLANNED_HOSP,
  INTIMATION
}

export interface PolicyHeaderV2 {
  polID: number;
  polNo: string;
  polTypeID: number;
  polSubTypeID: number;
  polDevelopmentOfficer: string;
  polDevelopmentAgent: string;
  polInsCompID: number;
  insuranceCompanyName: string;
  polStartDate: string;
  polEndDate: string;
  polFloater: boolean;
  polFloaterTypes: number;
  polAddUser: number;
  polHolderName: string
  polIsVIp: boolean;
  polbrokerid: number;
  mARevenueBranchId: number;
  mAServicingBranchId: number;
  pol_CorporateId: number;
  pol_GroupCorporateId: number;
  polInsOfficeId: number;
  polInsOfficeCode: string;
  polTPAId: number;
  polDeleteStatus: boolean;
  polSubTypeDesc: string;
  polTypeDesc: string;
  polActive: boolean;
  sourceDBType: DBTypeEnum;
  pol_Category: number;
  pol_CategoryName: string;
  polDomiFloater: boolean;
  polDomiAvailable: boolean;
  InsuranceCompanyShortName: string;
  polPaymentDirect: boolean;
}

export class DBTypeEnum {
  static readonly NOTAVAILABLE: string = 'NOTAVAILABLE';
  static readonly MA: string = 'MA';
  static readonly MAUHS: string = 'MAUHS';
  static readonly IWP: string = 'IWP';
  static readonly PLANNED_HOSP: string = 'PLANNED_HOSP';
  static readonly INTIMATION: string = 'INTIMATION';
}

export interface PribeneficiaryDetailV2 {
  priBenefNomineeRelName: string
  priBenefPremiumSTandSBC: number;
  priBenefServiceTaxAndSBC: number;
  priBenefNomineeRel: number;
  accountHolderName: string
  accountNumber: string
  bankLocation: string
  bankName: string
  bankTransferCode: string
  CASEmployeeId: number;
  dateOfJoining: Date;
  dateOfMarriage: Date;
  dateOfRelieving: Date;
  priBenef_Modified_Date: Date;
  priBenefAdd1: string
  priBenefAdd2: string
  priBenefBucket1: number;
  priBenefBucket1AmtAuthorised: number;
  priBenefBucket1AmtSpent: number;
  priBenefBucket2: number;
  priBenefBucket2AmtAuthorised: number;
  priBenefBucket2AmtSpent: number;
  priBenefBucket3: number;
  priBenefBucket3AmtAuthorised: number;
  priBenefBucket3AmtSpent: number;
  priBenefBucket4: number;
  priBenefBucket4AmtAuthorised: number;
  priBenefBucket4AmtSpent: number;
  priBenefBufferAmtAnnual: number;
  priBenefBufferAmtAuthorized: number;
  priBenefBufferAmtIncident: number;
  priBenefBufferAmtSpent: number;
  priBenefBufferAmtTotal: number;
  priBenefCityID: number;
  pribenefCumulativeBonus: number;
  priBenefDomiLimitOPD: number;
  priBenefDomiOPDSpent: number;
  pribenefDomiSpent: number;
  priBenefDomLimit: bigint;
  priBenefEmpCategoryID: number;
  priBenefEmpCode: string
  priBenefFamilyHospBenefitamount: number;
  priBenefFloaterSum: number;
  priBenefGrade: string
  priBenefHospBenefitLimit: number;
  priBeneficiaryName: string
  priBenefID: number;
  priBenefInsurerFloaterSum: number;
  priBenefInsurerTotalAmtAuthorised: number;
  priBenefInsurerTotalAmtSpent: number;
  priBenefIsVip: boolean;
  priBenefIsVRS: boolean;
  priBenefPABuffAmt: number;
  priBenefPin: string
  priBenefPolID: number;
  priBenefRefNo: string
  priBenefSpecialBufferAmt: number;
  priBenefSpecialBufferAmtAuthorized: number;
  priBenefSpecialBufferAmtSpent: number;
  priBenefThresholdAmt: number;
  priBenefTopup: number;
  priBenefTopupEffDate: Date;
  priBenefTotalAmtAuthorised: number;
  priBenefTotalAmtSpent: number;
  priBenefTotalPremium: number;
  pribenefunit: string
  primaryBenefExtnDetails: Array<PriBenefExtnDetailsV2>
  sourceDBType: DBTypeEnum;
  IsFraudEmployee: boolean;
}

interface PriBenefExtnDetailsV2 {
  CreatedOn: Date;
  modifiedOn: Date;
  pribenefExtnEmpCatagory: string;
  pribenefExtnEmpCode1: string;
  pribenefExtnEmpCode2: string;
  pribenefExtnEmpCode3: string;
  pribenefExtnEmpCode4: string;
  pribenefExtnEmpCode5: string;
  pribenefExtnID: number;
  pribenefID: BigInt;
  sourceDBType: DBTypeEnum;
}

export interface bankAccountDetailsResponse {
  isSuccess: boolean
  data: BankAccountDetail[]
}

export interface BankAccountDetail {
  accountHolderName: string
  accountNumber: string
  bankIFSCCode: string
  bankName: string
  branchName: string
  bankAddress: string
  chequeleafDocID: string
}

export interface UpdateBankDetailsDTO{
  accountHolderName: any;
  accountNumber: any;
  bankIFSCCode: any;
  bankName: any;
  branchName: any;
  bankAddress: any;
  chequeleafDocumentID: any;
}

export interface fetchChequeLeafReqDTO {
    filepath: string
}

export class ChequeUploadResponse extends BaseResponse {
  docId: string;
  chequeDetails: ChequeDetails;
  fileName: string;
}

export class ChequeDetails {
  name: string;
  accountNumber: string;
  ifscCode: string;
  bankName: string;
  bankAddress: string;
  branchName: string
}