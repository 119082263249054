import axios from "axios";
import { getCookieValue, postToNativeApp } from "../common/helpers";
import { addTimestampToUrl, getAccessToken, getXrefToken } from "../utils";
import { ILogDto } from "../utils/log.dto";

axios.defaults.headers =
	{
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache',
		'Expires': '0',
	} as any;

const API = process.env.REACT_APP_BASE_URL;
axios.interceptors.response.use(response => {
	return response;
}, error => {
	if (error.response.status === 401 || error.response.status === 403) {
			if (getCookieValue('source') === 'native') {
				postToNativeApp({ TOKEN_EXPIRED: true });
			}
			else {
				window.location.href = "/logout";
			}
	}
	return error.response;
});

function getHeaders() {
	return {
		'accept': 'application/json',
		'accesstoken': getAccessToken(),
		'x-ref-token': getXrefToken()
	};
}

function postHeaders() {
	return {
		'content-type': 'application/json',
		'accesstoken': getAccessToken(),
		'x-ref-token': getXrefToken()
	};
}

function patchHeaders() {
	return {
		'content-type': 'application/json',
		'accesstoken': getAccessToken(),
		'x-ref-token': getXrefToken()
	};
}

function deleteHeaders() {
	return {
		'content-type': 'application/json',
		'accesstoken': getAccessToken(),
		'x-ref-token': getXrefToken()
	};
}

export const postRequest = <T>(endpoint: string, data: T) => {
	let url = API + endpoint;
	url = addTimestampToUrl(url);
	return axios.post(url, data, { headers: postHeaders() });
}

export const patchRequest = <T>(endpoint: string, data: T) => {
	let url = API + endpoint;
	url = addTimestampToUrl(url);
	return axios.patch(url, data, { headers: patchHeaders() });
}

export const getRequest = (endpoint: string) => {
	let url = API + endpoint;
	url = addTimestampToUrl(url);
	return axios.get(url, { headers: getHeaders() });
}

export const getBlobRequest = (endpoint: string) => {
	let url = API + endpoint;
	url = addTimestampToUrl(url);
	return axios.get(url, { headers: getHeaders(), responseType:"blob" });
}

export const postBlobRequest = <T>(endpoint: string, data: T) => {
	let url = API + endpoint;
	url = addTimestampToUrl(url);
	return axios.post(url, data, { headers: postHeaders(), responseType: "blob" });
}

export const log = async (body: ILogDto) => {
	const ip = await getIp();
	await axios.post(API + '/master/log', { ...body, ip, url: API + body.url });
}

export const getIp = async () => {
	if (sessionStorage.getItem('ip')) {
		return sessionStorage.getItem('ip');
	}

	const res = await axios.get(API + '/master/ipAdd').catch(err => null);
	if (res && res.data && res.data?.ip !== null) {
		sessionStorage.setItem('ip', res.data?.ip);
		return res.data?.ip;
	}
}
export const deleteRequest = (endpoint: string) => axios
	.delete(API + endpoint, { headers: deleteHeaders() })    