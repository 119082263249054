import crypto from 'crypto';
export const getAccessToken = ():string => {
  return localStorage.getItem("accessToken") || "";
}

export const setAccessToken = (accessToken: string):void => {
  localStorage.setItem("accessToken",accessToken);
}

export const getXrefToken = ():string => {
  return localStorage.getItem("xreftoken") || localStorage.getItem("X-REF-DATA") || "";
}

export const setXrefToken = (xreftoken: string):void => {
  localStorage.setItem("xreftoken",xreftoken);
  localStorage.setItem("X-REF-DATA",xreftoken);
}

export const getMaid = ():string => {
  return localStorage.getItem("maid") || "";
}

export const setMaid = (maid: string):void => {
  localStorage.setItem("maid", maid);
}

export const getDbType = ():string => {
  return localStorage.getItem("dbType") || "";
}

export const setMasetDbTypeid = (dbType: string):void => {
  localStorage.setItem("dbType", dbType);
}

const ENC_KEY = 'ABEiM0RVZneImaq7zN3u/wABEiM0RV==';
const IV = 'ABEiM0RVZneImaq7';

export const encrypt = (input: string) => {
	let cipher = crypto.createCipheriv('aes256', ENC_KEY, IV);
	let encrypted = cipher.update(input, 'utf8', 'base64');
	encrypted += cipher.final('base64');
	return encrypted;
};

export const validateEmail = (loginValue:string) => {
  return String(loginValue)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhoneNumber = (value: any) => {
  let reg =  /^[6-9]\d{9}$/gi;;
  return reg.test(value);
};

export const navigateToRoute = () => {
  const intendedRoute = localStorage.getItem('intendedRoute');
  if(intendedRoute){
    localStorage.removeItem('intendedRoute');
    window.location.href = intendedRoute;
  }
}

export const addTimestampToUrl = (url: string): string => {
	if (url) {
		let new_url = new URL(url);
		let search_params = new_url.searchParams;
		search_params.set('dt', new Date().valueOf().toString());
		new_url.search = search_params.toString();
		return new_url.toString();
	}
};

export const formatDateToObject = (date: any, IsRequriedTime: boolean = false) => {
  if(!isNaN(date)){
    date  = parseInt(date);
  }
  const formattedDate = String(date).includes('/Date(')
    ? new Date(parseInt(date.replace('/Date(', '')))
    : new Date(/^\d+$/.test(date) ? parseInt(date) : date); 
  return formattedDate;
};

export const findAndReplace = (url:string) => {
  const regex = new RegExp(/\{\{([^{}]+)\}\}/g);
  const matches =  url.match(regex);
  if(!matches?.length) return url;
  matches.forEach((match) => {
   url = url.replace(match,localStorage.getItem(match.replaceAll('{','').replaceAll('}','')))
  }
  )
  return url;
}

// export const encrypt = (input:string) => {
//   const cipherText = CryptoJS.AES.encrypt(input, ENC_KEY).toString();
//   return cipherText;
// };

// // Usage example
// const encryptedString = encrypt('Hello, world!');
// console.log(encryptedString);


