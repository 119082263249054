import React, { useEffect, useState } from 'react'
import {
  NewHomeScreenUI
} from '@mediassistrepo/maven-old';

import { ClaimDetailsResponseDTO, FetchAllClaimDetails } from '../interfaces/signup.interface';
import { fetchAllClaimDetailsApi } from '../api/signupAPI';
import { useNavigate } from 'react-router';
import { tags } from '../constants/resetPassword';
import content from '../constants/linkPolicyContent.json';
import { RedirectionMap } from '../constants/linkPolicy';
import { getCookieValue, postToNativeApp } from '../common/helpers';

import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';

interface RedirectArgs {
  type: string;
  data: any;
  pageName: string;
}

const SignUp = () => {

  const [isLoader, setIsLoader] = useState(false as boolean);
  const navigate = useNavigate();
  const [isVisble,setVisible]=useState(false)
  const storedDateString = sessionStorage.getItem('showPopUp');

  useEffect(()=>{
    if(storedDateString!=="true"){
      sessionStorage.setItem('showPopUp', "false");
    }
  },[])
  useEffect(()=>{
    setVisible(true)
  },[storedDateString])
  const handleRedirect = (req: RedirectArgs) => {
    
    let sourceObj = RedirectionMap.get(req?.type);
    let redirectionUrl = `/link-policy`;

    if(req.type === tags.ecard){
      redirectionUrl += '?isEcardRequest=true';
    }else if(req.type === tags.claims){
      redirectionUrl += '?isClaimRequest=true';
      sourceObj = {...sourceObj, claimId: req?.data?.claimNumber}
    }else if(req.type === tags.intimateHospitalization){
      redirectionUrl += '?isIntimationRequest=true';
    }
    handleLinkPolicyRedirect(redirectionUrl,sourceObj, req.pageName);
  }

  const fetchClaimDetails = async (claimId: any) => {
    if (!(Number(claimId) > 0)) {
      return;
    }
    let requestObject: FetchAllClaimDetails = new FetchAllClaimDetails();
    requestObject.claimNumber = claimId;
    setIsLoader(true);
    let res = await fetchAllClaimDetailsApi(requestObject);
    setIsLoader(false);
    return res.data;
  };

  const handleLinkPolicyRedirect = (redirectUrl: string, data: any, pageName: string) => {
    navigate(redirectUrl, { state: {...data,pageName} });
  }

  const handleHelpPageRedirect = () => {
    navigate('/help', { state: { source: '/new-home' }});
  }

  return (storedDateString &&
    <>
    {isVisble&&
      <NewHomeScreenUI
        fetchClaimDetails={fetchClaimDetails}
        isLoader={isLoader}
        setIsLoader={setIsLoader}
        handleRedirect={handleRedirect}
        handleHelpPageRedirect={handleHelpPageRedirect}
        pageName='/signUp'
        dataLayerPush={dataLayerPush}
        handleNavigate={()=>{
          navigate("/logout")
        }}
        handleCloseBtn={()=>  navigate("/logout")}
        handleBrowseguest={()=>{
          navigate("/signup");
          sessionStorage.setItem('showPopUp', "true");
        }}
        handleLinkPolicyRedirect={()=>{  navigate("/link-policy")}}
      />}
      {!isVisble&&
      <NewHomeScreenUI
        fetchClaimDetails={fetchClaimDetails}
        dataLayerPush={dataLayerPush}
        isLoader={isLoader}
        setIsLoader={setIsLoader}
        handleRedirect={handleRedirect}
        handleHelpPageRedirect={handleHelpPageRedirect}
        pageName='/signUp'
        handleNavigate={()=>{
          navigate("/logout")
        }}
        handleCloseBtn={()=>  navigate("/logout")}
        handleBrowseguest={()=>{
          navigate("/signup");
        }}
        handleLinkPolicyRedirect={()=>{  navigate("/link-policy")}}
      />

      }
    </>
  )
}

export default SignUp