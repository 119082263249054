import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { fetchCount } from '../api/counterAPI';
import { log, postRequest } from '../app/postRequest.service';
import { LoginRequest, LoginResponse, OtpRequest, VerifyOtpRequest } from '../interfaces/login';
import { loginApi, sendEmailOtp, sendMobileOtp, ssoLoginApi, ssoSamlLoginApi, verifyOtp } from '../api/loginAPI';
import { getAccessToken, navigateToRoute, setAccessToken, setXrefToken } from '../utils';
import { getCookieValue, getPlatformFromCookie, postToNativeApp, testEmail } from '../common/helpers';
import { LoggerConstant , RouteConstant } from "../common/common.constants";
import { ILogDto } from '../utils/log.dto';
import { getLoggerDTO } from '../common/loggerDtoGenerator';

const platform = getPlatformFromCookie();

export interface CounterState {
  value: LoginResponse;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CounterState = {
  value: { accessToken: getAccessToken()},
  status: 'idle',
};

export const loginAsync = createAsyncThunk(
  'auth/loginAsync',
  async (request: LoginRequest) => {
    const response = await loginApi(request);
    return response.data;
  }
);

export const ssoLoginAsync = createAsyncThunk(
  'auth/ssoLoginAsync',
  async (request: LoginRequest) => {
    const response = await ssoLoginApi(request);
    return response.data;
  }
);

export const ssoSamlLoginAsync = createAsyncThunk(
  'auth/ssoSamlLogin',
  async (request: any) => {
    const response = await ssoSamlLoginApi(request);
    return response.data;
  }
)

export const sendMobileOtpAsync = createAsyncThunk(
  'auth/sendMobileOtpAsync',
  async (request: OtpRequest) => {
    const response = await sendMobileOtp(request);
    return response.data;
  }
);

export const sendEmailOtpAsync = createAsyncThunk(
  'auth/sendEmailOtpAsync',
  async (request: OtpRequest) => {
    const response = await sendEmailOtp(request);
    return response.data;
  }
);

export const verifyOtpAsync = createAsyncThunk(
  'auth/verifyOtpAsync',
  async (request: VerifyOtpRequest) => {
    const response = await verifyOtp(request); 
    return response.data;
  }
);

export const loginSlice = createSlice({
  name: 'auth',
  initialState,
  
  reducers: {
    logout: (state) => {
      state = null;
    },
    saveAccessToken: (state, action: PayloadAction<LoginResponse>) => {
      state.value =action.payload
    }
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        if (getCookieValue('source') === 'native') {
          postToNativeApp(action.payload)
       }
        setAccessToken(action?.payload?.accessToken|| "");
        setXrefToken(action?.payload?.xRefData|| "");
        state.value = action.payload;
        navigateToRoute();
      })
      .addCase(loginAsync.rejected, (state) => {
        state.status = 'failed';
      });

      builder
      .addCase(ssoLoginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(ssoLoginAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(ssoLoginAsync.rejected, (state) => {
        state.status = 'failed';
      });

      builder
      .addCase(sendMobileOtpAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendMobileOtpAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(sendMobileOtpAsync.rejected, (state,action) => {
        state.status = 'failed';
        state.value = action.payload;
        if(!state.value){
          state.value = new LoginResponse();
        }
        state.value.errorNo = Math.random();
      });

      builder
      .addCase(sendEmailOtpAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendEmailOtpAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })  
      .addCase(sendEmailOtpAsync.rejected, (state) => {
        state.status = 'failed';
      });

      builder
      .addCase(verifyOtpAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(verifyOtpAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        if (getCookieValue('source') === 'native') {
           postToNativeApp(action.payload)
        }
        if(action?.payload?.accessToken && action?.payload?.xRefData){
          setAccessToken(action?.payload?.accessToken as string);
          setXrefToken(action?.payload?.xRefData as string)
          navigateToRoute();
        }
        state.value = {...state.value, ...action.payload};

      })
      .addCase(verifyOtpAsync.rejected, (state,action) => {
        state.value = action.payload;
        state.status = 'failed';
      });

      builder
      .addCase(ssoSamlLoginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(ssoSamlLoginAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        if (getCookieValue('source') === 'native') {
           postToNativeApp(action.payload)
        }
        if(action?.payload?.accessToken && action?.payload?.xRefData){
          setAccessToken(action?.payload?.accessToken as string);
          setXrefToken(action?.payload?.xRefData as string)
          state.value = action.payload;
          navigateToRoute();
        }

      })
      .addCase(ssoSamlLoginAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { saveAccessToken } = loginSlice.actions;
export const getLogin = (state: RootState) => state.auth.value;
export const getLoginState = (state: RootState) => state.auth.status === "loading";
export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
