import React from 'react'
import {Policies} from '@mediassistrepo/homepage-component'
import { getMaid } from '../../utils';
import { GaEventDetails } from '../../common/helpers';
import { Homecategory, policy_clk } from '../../common/common.constants';
import { useNavigate } from 'react-router';
import { BeneficiariesResponse } from '../../interfaces/beneficiaries.interface';
import { dataLayerPush } from '../../utils/gtm';
import Constants from '../../constants/Constants';

interface PolicySectionProps {
    benefResLoading: boolean;
    beneficiariesResponse: BeneficiariesResponse;
}

const PolicySection = ({benefResLoading, beneficiariesResponse}: PolicySectionProps) => {
    const navigate = useNavigate();
  return (
    <>
    {(benefResLoading || beneficiariesResponse?.policyDetails?.length > 0) && <Policies
      skeletonLoader={benefResLoading}
      policies={beneficiariesResponse?.policyDetails || []}
      onPolicyClick={(e: string) => {
        dataLayerPush(Constants.Instance.MAIN_HOME_PAGE_CTA_INTERACTION, { 
          section_name: "Policies",
          cta_text: beneficiariesResponse?.policyDetails.filter((item)=>item.policyId === parseInt(e))[0].insuranceCompanyName || ""});
        GaEventDetails(policy_clk, Homecategory, getMaid(), "");
        navigate("/policy?policyId=" + e)
      }}
    />}
    </>
  )
}

export default PolicySection